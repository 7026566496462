html
    font-size: 20px
    font-family: "Gilroy", serif

*
    margin: 0
    padding: 0
    outline: none

ul
    list-style-type: none

a
    text-decoration: none
    color: #000

h1
    color: #0096db
    font-size: 1.35rem
    margin-bottom: 1rem

#toast-container > div
    width: 25vw

/**********/
/* header */
/**********/

header
    font-family: 'Gilroy', serif
    font-weight: 600
    font-style: normal
    font-size: 20px
    display: flex
    padding: 0.5rem 2.5rem
    border-bottom: 7px #86c5f4 solid
    box-sizing: border-box

    .menu_open
        display: none
        border: none
        padding: 0.25rem
        align-items: center
        justify-content: center
        cursor: pointer
        border-radius: 0.75rem
        position: absolute
        right: 7%
        top: 5%

        img
            width: 2rem
            height: 2rem

    #menu_open_input
        display: none

        &:checked + .menu
            transform: translateY(0)

    .menu
        width: 100%
        position: absolute
        top: 0
        transform: translateY(-100%)
        left: 0
        z-index: 1
        transition: all 1s ease
        background-color: #0096db
        padding: 1rem 2rem
        box-sizing: border-box
        display: none

        ul
            padding-left: 1rem

        > ul
            background-color: #fff

            li
                cursor: pointer
                color: #4D4D4D
                font-size: 14px
                font-weight: 700
                border-bottom: 1px solid #CCC
                align-items: center

                > a
                    padding-left: 5px
                    display: block
                    line-height: 3rem

                > ul
                    display: none

        .drop_menu
            position: relative

            > a::after
                content: url(/images/mark_down.png)
                width: 17px
                display: inline-block
                vertical-align: middle
                margin-left: 10px

            &:hover
                > ul
                    top: 100%

                    a
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, .3)
                        background-color: rgba(187, 187, 187, 0.53)

                    > li:hover
                        background-color: rgba(108, 170, 234, 0.54)

    .menu_close
        text-align: center
        background: #fff
        display: block
        border: none
        margin: 1rem 0
        padding: 0.5rem
        cursor: pointer

        img
            transform: rotate(180deg)

    .logo
        width: 10rem
        cursor: pointer
        padding: 0.5rem 1rem 0.5rem 0


@media (max-width: 980px)
    header
        #navbarSupportedContent
            display: none !important

//> ul
//    display: flex
//    align-items: flex-end
//    justify-content: space-between
//    width: 100%
//    padding-left: 3.5rem
//    flex-flow: wrap
//
//    div
//        display: flex
//        align-items: center
//        flex-flow: wrap
//
//    li
//        margin: 0 0.5rem
//
//        a
//            font-size: 0.85rem
//            padding: 0.5rem
//            font-family: 'Gilroy'
//            font-weight: 600
//            font-style: normal
//            display: block
//
//    > li
//        position: relative
//
//        a
//            color: #9b988d
//            display: flex
//            align-items: center
//            justify-content: flex-end
//
//            img
//                margin-left: 0.5rem
//                width: 0.85rem
//                height: 0.6rem
//
//        .top-sub-menu
//            position: absolute
//            display: none
//            width: 100%
//
//            li
//                background-color: #f3f3f3
//                box-shadow: 5px 0 10px -1px rgba(0, 0, 0, .1), -5px 15px 10px -1px rgba(0, 0, 0, .1)
//
//        &:hover > .top-sub-menu
//            display: block

/************/
/* left_bar */
/************/

.left_bar
    background-color: #f3f3f3
    min-width: 13rem
    padding-top: 1rem
    box-sizing: border-box
    min-height: 85vh

    ul
        > li
            padding: 0.5rem 0

            &:hover
                cursor: pointer

            a
                width: max-content
                display: flex
                align-items: baseline
                font-family: 'Gilroy'
                font-weight: normal
                font-style: normal
                font-size: 0.85rem

            > ul
                display: none
                box-shadow: 5px 0px 10px -1px rgba(0, 0, 0, 0.1), -5px 15px 10px -1px rgba(0, 0, 0, 0.1)
                z-index: 2

                li
                    min-width: 13rem
                    padding: 0.5rem
                    margin: 0
                    background-color: #f3f3f3
                    box-shadow: 5px 0 10px -1px rgba(0, 0, 0, 0.1), -5px 15px 10px -1px rgba(0, 0, 0, 0.1)

    li > a
        border-bottom: 3px solid transparent

    li:not([class]):hover > a
        border-bottom: 3px solid #629ccb

    .drop_menu
        position: relative

    .drop_menu
        > a::after
            content: ""
            background: url(/images/mark_left.png) no-repeat
            width: 9px
            height: 13px
            background-size: contain
            display: block
            margin-left: 0.5rem

    .drop_menu:hover > ul
        padding: 0
        display: block
        position: absolute
        top: 0
        left: 100%

main
    display: flex
