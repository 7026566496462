@import "~bootstrap"
@import "./fonts.css"
@import "~jquery-ui/themes/base/all.css"
@import "~jquery-datetimepicker/build/jquery.datetimepicker.min.css"
@import "~toastr"
@import "default"

.auth
    background-color: #359ece
    height: 100%
    width: 100%
    font-weight: normal
    font-style: normal

    .card_header
        padding: 2rem 1rem

        .social
            display: flex
            align-items: center
            line-height: 2rem
            color: #fff
            font-size: 18px

            a
                margin-left: 0.5rem
                width: 2rem
                background: #fff
                border: 1px solid #ccc
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), inset 0 0 2.5rem rgba(0, 0, 0, 0.1)
                border-radius: 1.25rem
                color: #000

            img
                width: 1.5rem
                margin-left: 0.2rem

    .card_body
        padding: 2rem 1rem 5rem 1rem
        border-top: 1px solid rgba(0, 0, 0, 0.125)
        border-bottom: 1px solid rgba(0, 0, 0, 0.125)

        .message
            position: absolute
            color: red
            font-weight: 300
            text-shadow: 1px 1px #a50000

        #auth_form
            input
                display: block
                align-items: center
                padding: 0.375rem 0.75rem
                margin: 1rem 0 1rem 0
                font-size: 1rem
                font-weight: 400
                line-height: 1.5
                color: #495057
                text-align: left
                white-space: nowrap
                background-color: white
                border: 1px solid #ced4da
                border-radius: 0.25rem

            .remember
                padding-top: 30px

                input
                    display: inline-block
                    margin: 0 5px

            #auth_form_btn
                margin-top: 3.5rem
                color: #0096db
                border: 2px #009fe3 solid
                font-size: 0.95rem
                padding: 0.75rem 4rem
                border-radius: 1rem
                background-color: #ffffff
                cursor: pointer

    .card_footer
        padding: 1rem

        p
            line-height: 2rem

        a
            color: #fff
            text-decoration: underline
            padding: 0.5rem
            margin: 0 0.5rem

    #dialog_newuser_checksms
        display: none

    #dialog_newuser
        display: none

.dialog_authSms
    .timer
        font-size: 20px
        display: block
        margin: 1rem

    input[type="text"]
        display: inline-block
        width: 95%
        padding: 10px
        border-radius: 10px
        box-shadow: 1px 1px 3px #000

    .phone
        font-size: 1.5em
        padding: 0 15px

        input
            margin: 0 10px

    .ui-dialog-content
        font-style: normal
        color: #000
        font-size: 14px
        font-weight: 500
        line-height: 25px

        > p
            margin: 10px 0
            background-color: #f3f3f3
            padding: 15px
            font-size: 18px

        #timer
            margin: 1rem 0
            text-align: center
            font-size: 20px
            display: block

        .clickable
            cursor: pointer
            color: #4c2cff

    .ui-dialog-titlebar
        text-align: center
        color: #FFFFFF
        background-color: #359ece
        border: 1px solid #359ece
        border-radius: 0.5rem
        text-shadow: 1px 1px 2px #000

    .ui-dialog-buttonpane
        background-color: #f3f3f3
        border-radius: 0.5rem
        border: 1px solid #f3f3f3

        .btn-primary
            border: 1px solid #359ece
            border-radius: 0.5rem
            margin: 0 20px
            color: #fff
            text-shadow: 1px 1px 2px #000
            background-color: #359ece
            min-width: 5rem

            &:active
                color: #359ece
                background-color: #fff

            &:disabled
                background-color: #808080
                color: #fff

        .btn-default
            border: 1px solid #25213f
            border-radius: 0.5rem

@media (max-width: 695px)
    .left_bar
        display: none
